import { TextField, Tooltip, Card, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getActiveUnpaidGroupUsers } from "../../api/group";
import useAuth from "../../context/useAuth";
import { EPaymentStatus } from "../../enums/paymentStatusEnum";
import GroupStudent from "../../interfaces/groupUser";
import useDebounce from "../../utils/useDebounce";
import MultiSelect from "../form/MultiSelect";
import StudentModal from "../group/studentModal";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton, Menu, MenuItem } from "@mui/material";

export const paymentsColor = {
  [EPaymentStatus.PAID]: "#097969",
  [EPaymentStatus.UNPAID]: "#D98282",
  [EPaymentStatus.PENDING]: "#ff9e0e",
};

const Index = ({
  teachers,
  languageLevels,
  checkedLocation,
  setCheckedLocation,
}: {
  checkedLocation: any[];
  setCheckedLocation: any;
  teachers: { name: string; value: string }[];
  languageLevels: { name: string; value: string }[];
}) => {
  const { locations } = useAuth();
  const [checkedTeachers, setCheckedTeachers] = useState<any>([]);
  const [checkedLanguageLevels, setCheckedLanguageLevels] = useState<any>([]);
  const [groupUsers, setGroupUsers] = useState<GroupStudent[]>([]);
  const [currentStudent, setCurrentStudent] = useState<GroupStudent>(
    groupUsers[0]
  );

  const [searchInput, setSearchInput] = useState<string>("");
  const [page, setPage] = useState(0);
  const [records, setRecords] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [viewModalOpen, setViewModalOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openedMenu, setOpenedMenu] = useState<string | null>(null);
  const [open, setOpen] = useState(false);

  const debouncedSearch = useDebounce(searchInput);

  const columns: GridColDef[] = [
    {
      field: "student_name",
      headerName: "Student Name",
      flex: 1,
      minWidth: 150,
      renderCell: ({ row }: GridRenderCellParams) =>
        `${row.student.first_name} ${row.student.last_name}`,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 300,
      renderCell: ({ row }: GridRenderCellParams) => row.student.email,
    },
    {
      field: "identity_card",
      headerName: "Identity Card",
      flex: 1,
      minWidth: 150,
      renderCell: ({ row }: GridRenderCellParams) => row.student.identity_card,
    },
    {
      field: "group_name",
      headerName: "Group Name",
      flex: 1,
      minWidth: 150,
      renderCell: ({ row }: GridRenderCellParams) => row.group?.name,
    },
    {
      field: "location",
      headerName: "Location",
      flex: 1,
      minWidth: 150,
      renderCell: ({ row }: GridRenderCellParams) => row.group?.location?.name,
    },
    {
      field: "level",
      headerName: "Level",
      flex: 1,
      minWidth: 150,
      renderCell: ({ row }: GridRenderCellParams) => {
        const levelId = row.group?.language_level;
        const level = languageLevels.find((l) => l.value === levelId);
        return level?.name || "-";
      },
    },
    {
      field: "payments",
      headerName: "Payments",
      flex: 1,
      minWidth: 200,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "5px",
            }}
          >
            {row.payment.map((p: any, index: any) => {
              const paymentMonth = dayjs(p.month).format("MMM");
              return (
                <Tooltip
                  title={`Month ${index + 1}: ${paymentMonth}`}
                  key={p._id}
                  placement="top"
                >
                  <Box
                    sx={{
                      width: "20px",
                      height: "20px",
                      backgroundColor:
                        paymentsColor[p.status as EPaymentStatus],
                      borderRadius: "100%",
                    }}
                  />
                </Tooltip>
              );
            })}
          </Box>
        </Box>
      ),
    },
    {
      field: "bookPayment",
      headerName: "Book Payment",
      flex: 1,
      minWidth: 150,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Tooltip title="Book Payment" placement="top">
          <Box
            sx={{
              width: "20px",
              height: "20px",
              backgroundColor: row.bookPayment?.amount
                ? paymentsColor[row.bookPayment?.status as EPaymentStatus]
                : paymentsColor[EPaymentStatus.UNPAID],
              borderRadius: "100%",
            }}
          />
        </Tooltip>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params: GridRenderCellParams) => (
        <div>
          <IconButton
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(e) => openMenu(e, params.row.id)}
          >
            <MoreVertIcon />
          </IconButton>
          {openedMenu === params.row.id && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open
              onClose={closeMenu}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  closeMenu();
                  setViewModalOpen(true);
                  setCurrentStudent(params.row);
                }}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: "#A250A6",
                  width: "100%",
                  gap: 5,
                }}
              >
                View
                <VisibilityIcon />
              </MenuItem>
            </Menu>
          )}
        </div>
      ),
      flex: 1,
      maxWidth: 70,
    },
  ];

  const loadActiveUnpaidGroupUsers = async () => {
    const { data: res, records } = await getActiveUnpaidGroupUsers({
      page,
      name: debouncedSearch,
      status: EPaymentStatus.UNPAID,
      teachers: checkedTeachers.map((item: any) => item.value),
      locations: checkedLocation.map((item) => item.value),
      languageLevels: checkedLanguageLevels.map((item: any) => item.value),
    });
    if ("error" in res) {
      return toast.error(res.message);
    } else {
      const mappedGroupUsers = res.map((groupUser: any) => {
        return {
          ...groupUser,
          id: groupUser._id,
        };
      });
      setGroupUsers(mappedGroupUsers);
      setRecords(records);
      setLoading(false);
    }
  };

  const openMenu = (event: React.MouseEvent<HTMLElement>, id: string) => {
    setAnchorEl(event.currentTarget);
    setOpenedMenu(id);
    setOpen(true);
  };

  const closeMenu = () => {
    setAnchorEl(null);
    setOpenedMenu(null);
    setOpen(false);
  };

  useEffect(() => {
    loadActiveUnpaidGroupUsers();
  }, [
    debouncedSearch,
    checkedLocation,
    checkedTeachers,
    checkedLanguageLevels,
    page,
  ]);

  return (
    <Card elevation={0} sx={{ py: 2, px: 2 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Add Payment</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: { xs: "column", lg: "row" },
          justifyContent: "space-between",
          pb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: { xs: "column", lg: "row" },
            justifyContent: "space-between",
          }}
        >
          <MultiSelect
            values={checkedLocation}
            setValues={setCheckedLocation}
            options={locations}
            label="Location"
          />
          <MultiSelect
            values={checkedTeachers}
            setValues={setCheckedTeachers}
            options={teachers}
            label="Teacher"
          />
          <MultiSelect
            values={checkedLanguageLevels}
            setValues={setCheckedLanguageLevels}
            options={languageLevels}
            label="Language Level"
          />
          <TextField
            size="small"
            sx={{ mr: { lg: 2 }, width: { xs: "100%", lg: "300px" } }}
            placeholder="Search by name ..."
            type="text"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </Box>
      </Box>

      <Box sx={{ height: 650, width: "100%" }}>
        <DataGrid
          rows={groupUsers}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          loading={loading}
          onPageChange={(e) => {
            setPage(e);
          }}
          rowCount={records}
          paginationMode="server"
          disableColumnFilter
          disableColumnSelector
        />
      </Box>
      {currentStudent && (
        <StudentModal
          paymentPageView
          open={viewModalOpen}
          setOpen={setViewModalOpen}
          student={currentStudent}
          reloadData={loadActiveUnpaidGroupUsers}
        />
      )}
    </Card>
  );
};

export default Index;
