import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import dayjs from "dayjs";
import React, { Dispatch, SetStateAction, useState } from "react";
import { toast } from "react-toastify";
import { recordBookPayment, updateGroupStudent } from "../../../api/group";
import useAuth from "../../../context/useAuth";
import { EPaymentStatus } from "../../../enums/paymentStatusEnum";
import GroupStudent from "../../../interfaces/groupUser";
import { formatDate } from "../../../utils/formatDate";
import { twoDecimalInputChange } from "../../../utils/twoDecimalInputChange";
import PaymentCard from "./PaymentCard";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "90%",
    sm: "90%",
    md: "90%",
    lg: "80%",
    xl: "60%",
  },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
  overflow: "auto",
};

interface ModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  student: GroupStudent;
  paymentPageView?: boolean;
  reloadData: () => void;
}

const StudentModal: React.FC<ModalProps> = ({
  paymentPageView = false,
  open,
  setOpen,
  student,
  reloadData,
}) => {
  const [bookPaymentAmount, setBookPaymentAmount] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const { student: studentUser, payment } = student;
  const { user, loadPendingPaymentsCount } = useAuth();

  const handleBookPayment = async () => {
    try {
      if (bookPaymentAmount <= 0) {
        return toast.error("The amount should be higher than 0");
      }
      const response = await recordBookPayment(
        student._id.toString(),
        bookPaymentAmount,
        paymentPageView ? EPaymentStatus.PAID : EPaymentStatus.PENDING
      );
      if (response.status) {
        if (!user) {
          return toast.error("User is not logged in");
        }

        await reloadData();
        setOpen(false);

        setBookPaymentAmount(0);
        toast.success("Book Payment Recorded Successfully");
      }
    } catch (error: any) {
      return toast.error(error.message);
    }
  };

  const handleBookPaymentChange = async (
    debitAmount: number,
    status: EPaymentStatus,
    index: number,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    try {
      if (debitAmount <= 0 && status !== EPaymentStatus.UNPAID) {
        setLoading(false);
        return toast.error("Debit amount must be bigger than zero");
      }

      const response = await recordBookPayment(
        student._id.toString(),
        debitAmount,
        status
      );

      if (response.status) {
        if (!user) {
          setLoading(false);
          return toast.error("User is not logged in");
        }

        await reloadData();
        setOpen(false);
        await loadPendingPaymentsCount();
        toast.success("Book Payment Updated Successfully");
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      return toast.error(error.message);
    }
  };

  const handlePaymentChange = async (
    debitAmount: number,
    status: EPaymentStatus,
    index: number,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    try {
      const id = student._id;
      setLoading(true);
      setDisabled(true);
      if (debitAmount <= 0 && status !== EPaymentStatus.UNPAID) {
        setLoading(false);
        setDisabled(false);
        return toast.error("Debit amount must be bigger than zero");
      }
      const newPayment = payment.map((item, i) =>
        i === index
          ? {
              ...item,
              status: status,
              amount: debitAmount,
              edited_by: {
                _id: user?._id,
                first_name: user?.first_name,
                last_name: user?.last_name,
              },
              last_edited: new Date(),
            }
          : item
      );

      const res = await updateGroupStudent(id, newPayment, {
        debit_amount: debitAmount,
        student_id: student.student._id,
        group_id: paymentPageView
          ? typeof student.group !== "string"
            ? student.group._id
            : student.group
          : student.group,
        location: student.student.location,
      });
      if ("error" in res) {
        setLoading(false);
        setDisabled(false);
        return toast.error(res.message);
      }
      await reloadData();
      setOpen(false);
      await loadPendingPaymentsCount();
      toast.success(res.message);
      setLoading(false);
      setDisabled(false);
    } catch (error: any) {
      return toast.error(error.message);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography component="h1" fontSize="2rem" mb={2}>
          {student.transferredToThisGroup ? "Edit Student" : "View Student"}
        </Typography>
        <Paper elevation={3} sx={{ p: 2 }}>
          <Typography
            component="div"
            sx={{ display: "flex", alignItems: "center", fontSize: "2rem" }}
          >
            <Avatar
              sx={{
                width: 70,
                height: 70,
                fontSize: "2rem",
                mr: 2,
                backgroundColor: "primary.main",
              }}
            >
              {studentUser.first_name[0] + studentUser.last_name[0]}
            </Avatar>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box sx={{ fontSize: "20px" }} component="div">
                Name:
                <Typography component="h3" fontSize="1.5rem">
                  {`${studentUser.first_name} ${studentUser.last_name}`}
                </Typography>
              </Box>
              <Box sx={{ width: "50%" }}>
                <Box sx={{ fontSize: "20px" }}>
                  Certifications:
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    {studentUser.certifications &&
                      studentUser.certifications.map(
                        (s: string, index: number) => {
                          return (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                marginRight: "5px",
                              }}
                            >
                              <Typography component="h3" fontSize="1.5rem">
                                {s}
                              </Typography>
                              <WorkspacePremiumIcon />
                            </Box>
                          );
                        }
                      )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Typography>
        </Paper>
        {student.lastTransferDetail && (
          <Paper elevation={3} sx={{ padding: 2, marginTop: 2 }}>
            <Typography variant="h3" component="h3" fontSize="1.7rem">
              Transfer History
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "column",
                  lg: "row",
                  xl: "row",
                },
                justifyContent: "space-between",
                padding: 1,
                margin: 1,
              }}
            >
              <Box>
                <Typography variant="body1" sx={{ marginBottom: 1 }}>
                  From:{" "}
                  <Typography component="span" color="text.secondary">
                    {student.lastTransferDetail.fromGroup.name}
                  </Typography>
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: 1 }}>
                  To:{" "}
                  <Typography component="span" color="text.secondary">
                    {student.lastTransferDetail.toGroup.name}
                  </Typography>
                </Typography>
              </Box>
              <Box>
                <Typography variant="body1" sx={{ marginBottom: 1 }}>
                  Transfer Date:{" "}
                  <Typography component="span" color="text.secondary">
                    {formatDate(
                      new Date(student.lastTransferDetail.transferDate),
                      {
                        hour: "2-digit",
                        minute: "2-digit",
                      }
                    )}
                  </Typography>
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: 1 }}>
                  By:{" "}
                  <Typography component="span" color="text.secondary">
                    {`${student.lastTransferDetail.transferredBy.first_name} ${student.lastTransferDetail.transferredBy.last_name}`}
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </Paper>
        )}
        <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
          <Typography component="h3" fontSize="1.7rem">
            Payments
          </Typography>
          <Grid container spacing={2}>
            {payment.map((pay: any, index) => {
              const lastEditedBy = pay.edited_by.first_name
                ? `${pay.edited_by.first_name} ${pay.edited_by.last_name}`
                : "";
              const lastEditedDate = new Date(pay.last_edited);
              const date = new Date(pay.month);
              const month = date.toLocaleString("default", { month: "long" });

              return (
                <PaymentCard
                  cardsB2={payment.length == 4 ? true : false}
                  key={index}
                  index={index}
                  month={month}
                  date={date}
                  status={pay.status}
                  amount={pay.amount}
                  transferStatus={pay.transfer}
                  lastEditedBy={lastEditedBy}
                  lastEditedDate={lastEditedDate}
                  handleClick={handlePaymentChange}
                  disabled={disabled}
                  transferred={student.transferredFromThisGroup}
                  paymentPageView={paymentPageView}
                  groupUserID={student._id.toString()}
                />
              );
            })}
          </Grid>
        </Paper>
        <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h5">Book Payment</Typography>
            {!student?.bookPayment?.amount ||
            !student?.bookPayment?.edited_by ? (
              <Grid container spacing={2}>
                <PaymentCard
                  cardsB2={false}
                  index={0}
                  date={new Date()}
                  status={EPaymentStatus.UNPAID}
                  amount={0}
                  transferStatus={false}
                  lastEditedBy={""}
                  lastEditedDate={new Date()}
                  handleClick={handleBookPaymentChange}
                  disabled={false}
                  transferred={false}
                  isBookPayment={true}
                  groupUserID={student._id.toString()}
                />
              </Grid>
            ) : (
              <Grid container spacing={2}>
                <PaymentCard
                  cardsB2={false}
                  index={0}
                  date={new Date(student?.bookPayment?.changed_at)}
                  status={student?.bookPayment?.status}
                  amount={student?.bookPayment?.amount}
                  transferStatus={false}
                  lastEditedBy={`${student?.bookPayment?.edited_by?.first_name} ${student?.bookPayment?.edited_by?.last_name}`}
                  lastEditedDate={new Date(student?.bookPayment?.changed_at)}
                  handleClick={handleBookPaymentChange}
                  disabled={false}
                  transferred={false}
                  isBookPayment={true}
                  groupUserID={student._id.toString()}
                />
              </Grid>
            )}
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default StudentModal;
