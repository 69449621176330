import axios from "axios";
import { toast } from "react-toastify";
import api from "../utils/api";

interface Receipt {
  groupUserID: {
    student: {
      first_name: string;
      last_name: string;
    };
  };
  receipt_name: string;
  file_url: string;
  file_type: string;
  date: number;
  // paymentID: string;
}

export async function createReceipt({
  groupUserID,
  receipt_name,
  file,
}: // paymentID,
{
  groupUserID: string;
  receipt_name: string;
  file: File;
  paymentID: string;
}) {
  try {
    const file_name = file.name;
    const file_type = file.type;
    const date = Date.now();

    const selectedFileUrl = await api.get("receipts/generatePresignedUrl", {
      params: {
        groupUserID,
        receipt_name,
        file_name,
        file_type,
        // paymentID,
      },
    });

    if (!selectedFileUrl.data.status) {
      return {
        error: true,
        message: selectedFileUrl.data.message,
      };
    }

    const uploadedFile = await axios.put(
      selectedFileUrl.data.data.presignedUrl,
      file,
      {
        headers: {
          "Content-Type": file_type,
        },
      }
    );

    if (uploadedFile.status !== 200) {
      return {
        error: true,
        message: "Something went wrong during file upload!",
      };
    }

    const response = await api.post("receipts", {
      groupUserID,
      receipt_name,
      file_url: selectedFileUrl.data.data.file_url,
      file_type,
      date,
      // paymentID,
    });

    return response.data;
  } catch (e: any) {
    console.error("Receipt creation error:", e);
    return {
      error: true,
      message: e.response?.data?.message || "Failed to create receipt",
    };
  }
}

export async function getReceiptsByGroupUser(groupUserID: string) {
  try {
    const response = await api.get(`receipts/${groupUserID}`);
    return response.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response?.data?.message || "Failed to fetch receipts",
    };
  }
}

export const downloadReceipt = async (fileKey: string, receipt: Receipt) => {
  try {
    const response = await api.get(`/receipts/getFileUrl?key=${fileKey}`);

    if (response.data && response.data.data && response.data.data.url) {
      const fileResponse = await fetch(response.data.data.url);
      const blob = await fileResponse.blob();

      const isBookPayment = receipt.receipt_name === "Book-Payment";
      const fileName = `${receipt.groupUserID.student.first_name}_${
        receipt.groupUserID.student.last_name
      }-${
        isBookPayment ? "BookPayment" : receipt.receipt_name.split("-")[1]
      }.${fileKey.split(".").pop()}`;

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
      window.URL.revokeObjectURL(url);
      toast.success("Download started");
    } else {
      toast.error("Failed to download file");
    }
  } catch (error) {
    console.error("Error downloading file:", error);
    toast.error("Error downloading file");
  }
};
