import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Tooltip,
  Typography,
  Modal,
  IconButton,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { downloadReceipt } from "../../api/receipts";
import api from "../../utils/api";

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 0,
  outline: 'none',
  maxWidth: '95vw',
  maxHeight: '95vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 1,
};

interface ViewReceiptProps {
  receipts: any[];
  groupUserID: string;
  paymentStatus: string;
}

const ViewReceipt = ({ receipts, groupUserID, paymentStatus }: ViewReceiptProps) => {
  const [loadingReceiptId, setLoadingReceiptId] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");

  const receipt = receipts[0];

  if (!receipt) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography>No receipt found.</Typography>
      </Box>
    );
  }

  const handleViewImage = async (fileKey: string) => {
    try {
      setLoadingReceiptId(fileKey);
      const response = await api.get(`/receipts/getFileUrl?key=${fileKey}`);
      
      if (response.data && response.data.data && response.data.data.url) {
        setSelectedImageUrl(response.data.data.url);
        setModalOpen(true);
      } else {
        toast.error("Failed to load image");
      }
    } catch (error) {
      console.error("Error viewing image:", error);
      toast.error("An error occurred while viewing the image");
    } finally {
      setLoadingReceiptId(null);
    }
  };

  const handleDownload = async (fileKey: string) => {
    try {
      setLoadingReceiptId(fileKey);
      await downloadReceipt(fileKey, receipt);
    } catch (error) {
      console.error("Error downloading file:", error);
      toast.error("Error downloading file");
    } finally {
      setLoadingReceiptId(null);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>
        Receipt Details
      </Typography>
      
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            <strong>Receipt Name:</strong> {receipt?.groupUserID?.student?.first_name} {receipt?.groupUserID?.student?.last_name} - {receipt.receipt_name.split('-')[1]}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            <strong>Date:</strong> {dayjs(receipt.date).format("DD.MM.YYYY")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
            <Button
              variant="contained"
              startIcon={<VisibilityIcon />}
              onClick={() => handleViewImage(receipt.file_url)}
              disabled={loadingReceiptId === receipt.file_url}
            >
              {loadingReceiptId === receipt.file_url ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "View Image"
              )}
            </Button>
            <Button
              variant="outlined"
              startIcon={<CloudDownloadIcon />}
              onClick={() => handleDownload(receipt.file_url)}
              disabled={loadingReceiptId === receipt.file_url}
            >
              {loadingReceiptId === receipt.file_url ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Download"
              )}
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="image-modal"
        aria-describedby="receipt-image-view"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          },
        }}
      >
        <Box sx={modalStyle}>
          <Box sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            zIndex: 1,
          }}>
            <IconButton
              onClick={() => setModalOpen(false)}
              sx={{
                color: 'white',
                bgcolor: 'rgba(0, 0, 0, 0.5)',
                '&:hover': {
                  bgcolor: 'rgba(0, 0, 0, 0.7)',
                },
              }}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
          }}>
            <img
              src={selectedImageUrl}
              alt="Receipt"
              style={{
                maxWidth: '95vw',
                maxHeight: '90vh',
                objectFit: 'contain',
                display: 'block',
              }}
            />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ViewReceipt;
