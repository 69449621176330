import { Card, Checkbox, FormControlLabel, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { updateTeacherPayment } from "../../../api/group";
import { TeacherPayment } from "../../../interfaces/group";
import useAuth from "../../../context/useAuth";

interface TeacherPaymentsProps {
  groupId: string;
  teacherPayments: TeacherPayment[];
}

const TeacherPayments: React.FC<TeacherPaymentsProps> = ({
  groupId,
  teacherPayments,
}) => {
  const [payments, setPayments] = useState<TeacherPayment[]>(teacherPayments);
  const { user } = useAuth();
  useEffect(() => {
    setPayments(teacherPayments);
  }, [teacherPayments]);

  const handlePaymentChange = useCallback(
    async (paymentId: string) => {
      try {
        await updateTeacherPayment(groupId, paymentId);
        setPayments((prevPayments) =>
          prevPayments.map((payment) =>
            payment._id === paymentId ? { ...payment, isPaid: true } : payment
          )
        );
      } catch (error) {
        console.error("Error updating teacher payment:", error);
      }
    },
    [groupId]
  );

  if (!payments || payments.length === 0) {
    return null;
  }

  return (
    <Card sx={{ p: 2, mb: 2 }}>
      <Typography variant="h6" gutterBottom>
        Teacher Payments
      </Typography>
      {payments.map(({ _id, isPaid, month }) => (
        <FormControlLabel
          key={_id}
          control={
            <Checkbox
              checked={Boolean(isPaid)}
              onChange={() => handlePaymentChange(_id)}
              disabled={isPaid || user?.user_level !== 1}
            />
          }
          label={dayjs(month).format("MMMM YYYY")}
        />
      ))}
    </Card>
  );
};

export default TeacherPayments;
