import DownloadIcon from "@mui/icons-material/Download";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
const XLSX = require("xlsx");
import {
  downloadCsvTemplate,
  generateMultipleCertificates,
} from "../../../api/certificate";

const MultipleCertificate = () => {
  const [file, setFile] = useState<File | undefined>(undefined);
  const [error, setError] = useState<string>("");
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0] || null;

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

    if (
      selectedFile &&
      (selectedFile.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        selectedFile.type === "application/vnd.ms-excel")
    ) {
      setFile(selectedFile);
      setError("");
    } else {
      setError("Please upload a valid Excel file (XLSX or XLS).");
      setFile(undefined);
    }
  };

  const convertExcelToArray = (): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      if (!file) return reject(new Error("No file selected"));

      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const data = e.target?.result;
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          resolve(jsonData);
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = () => reject(new Error("File reading has failed."));
      reader.readAsArrayBuffer(file);
    });
  };

  const handleGenerateCertificates = async () => {
    if (!file) {
      setError("Please select a file");
      return;
    }

    try {
      setUploading(true);
      const users = await convertExcelToArray();
      const response = await generateMultipleCertificates(users);

      if (response.error) {
        throw new Error(response.message);
      }

      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      setFile(undefined);
      setError("");
    } catch (err) {
      console.error("Error generating certificates:", err);
      setError("An error occurred while processing the certificates.");
    } finally {
      setUploading(false);
    }
  };

  const handleDownloadTemplate = async () => {
    try {
      const response = await downloadCsvTemplate();
      if (response.error) {
        setError(response.message);
      }
    } catch (err) {
      console.error("Error downloading template:", err);
      setError("Error downloading template");
    }
  };

  return (
    <Card elevation={3} sx={{ maxWidth: 600, ml: 3, mt: 5 }}>
      <CardContent>
        <Typography variant="h5" mb={2} align="center">
          Generate Multiple User Certificates
        </Typography>
        <Divider sx={{ mb: 3 }} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              startIcon={<DownloadIcon />}
              onClick={handleDownloadTemplate}
            >
              Download Excel Template
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              variant="outlined"
              component="label"
              startIcon={<UploadFileIcon />}
            >
              Upload Excel
              <input
                ref={fileInputRef}
                type="file"
                hidden
                accept=".xlsx,.xls"
                onChange={handleFileUpload}
              />
            </Button>
            {file && (
              <Typography variant="body2" mt={1}>
                Uploaded File: {file.name}
              </Typography>
            )}
          </Grid>

          {error && (
            <Grid item xs={12}>
              <Typography color="error">{error}</Typography>
            </Grid>
          )}
        </Grid>

        <Box display="flex" justifyContent="center" sx={{ mt: 3 }}>
          <Button
            sx={{ width: "300px" }}
            variant="contained"
            color="primary"
            onClick={handleGenerateCertificates}
            disabled={!file || uploading}
          >
            {uploading ? (
              <CircularProgress size={25} />
            ) : (
              "Generate Certificates"
            )}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default MultipleCertificate;
